<template>
  <div class="tech-services">
    <div class="application">
      <div class="application-content">
        <h2>{{$t('TechServices.application.title')}}</h2>
        <div>
          <img src="./../assets/tech_identity_check.png" alt="Identity Check" />
          <h3>{{$t('TechServices.application.ic_title')}}</h3>
          <p>{{$t('TechServices.application.ic_text')}}</p>
        </div>
        <div>
          <img
            src="./../assets/tech_fraud_identification.png"
            alt="Fraud Identification"
          />
          <h3>{{$t('TechServices.application.fi_title')}}</h3>
          <p>{{$t('TechServices.application.fi_text')}}</p>
        </div>
        <div>
          <img src="./../assets/tech_credit_score.png" alt="Credit Score" />
          <h3>{{$t('TechServices.application.cs_title')}}</h3>
          <p>{{$t('TechServices.application.cs_text')}}</p>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="top-content">
        <article>
          <h3>{{$t('TechServices.main_content.first_title')}}</h3>
          <p>
            {{$t('TechServices.main_content.first_text1')}}
            <br />{{$t('TechServices.main_content.first_text2')}}
            <br />{{$t('TechServices.main_content.first_text3')}}
            <br />{{$t('TechServices.main_content.first_text4')}}
          </p>
        </article>
        <div>
          <img
            src="./../assets/tech_the_idea_behind_the_project.png"
            alt="THE IDEA BEHIND THE PROJECT"
          />
        </div>
      </div>
      <div class="center-content">
        <div>
          <img src="./../assets/tech_how_it_works.png" alt="HOW IT WORKS" />
        </div>
        <article>
          <h3>{{$t('TechServices.main_content.second_title')}}</h3>
          <p>{{$t('TechServices.main_content.second_text')}}</p>
        </article>
      </div>
      <div class="bottom-content">
        <article>
          <h3>{{$t('TechServices.main_content.third_title')}}</h3>
          <p>{{$t('TechServices.main_content.third_text')}}</p>
        </article>
        <div>
          <img
            src="./../assets/tech_continuous_improvement.png"
            alt="CONTINUOUS IMPROVEMENT"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TechServices'
}
</script>

<style lang="scss">
.tech-services {
  .application {
    width: 100%;
    min-width: 1200px;
    .application-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      h2 {
        padding: 100px 0 60px;
        font: normal bold 40px Arial;
        color: #2a2a2a;
      }
      > div {
        display: inline-block;
        vertical-align: top;
        width: 360px;
        box-sizing: border-box;
        padding: 20px;
        padding-bottom: 50px;
        text-align: center;
        color: #2a3037;
        border: 1px solid #dedede;
        border-radius: 5px;
        img {
          height: 80px;
          width: auto;
        }
        h3 {
          padding: 30px 0 35px;
          font: normal bold 22px Arial;
        }
        p {
          height: 184px;
          font: normal 500 15px/23px Arial;
          text-align: justify;
          letter-spacing: 1px;
        }
      }
      > div:nth-child(3) {
        margin: 0 50px;
      }
    }
  }
  .main-content {
    width: 100%;
    min-width: 1200px;
    > div {
      width: 1200px;
      margin: 0 auto;
      padding: 80px 0 50px;
      display: flex;
      justify-content: space-between;
      article {
        h3 {
          font: normal bold 40px Arial;
          color: #2a2a2a;
        }
        p {
          font: 21px Arial;
          color: #2a2a2a;
          line-height: 32px;
          text-align: justify;
        }
      }
    }
    .top-content,
    .center-content {
      article {
        width: 740px;
        h3 {
          padding-bottom: 50px;
        }
      }
      div {
        width: 385px;
        padding-top: 40px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
    .center-content {
      > div {
        padding: 0;
      }
      article {
        padding-top: 80px;
      }
    }
    .bottom-content {
      article {
        width: 585px;
        padding-top: 25px;
        h3 {
          padding-bottom: 50px;
        }
      }
      > div {
        width: 500px;
        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
</style>
